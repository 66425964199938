/* _navigation.scss */
// contains styles for all navigation systems.
// ex. main navigation, sub navigation, pagination, breadcrumbs,

.menu {
  @include reset;
  @include no-bullets;
}

@import 'navigation/main-nav';
@import 'navigation/breadcrumb';
@import 'navigation/pager';
@import 'navigation/footer-nav';
