/* =============================================================================
REUSABLE SITEWIDE PLACEHOLDERS / EXTENDS
========================================================================== */

//TRANSITIONS
%transition {
  -webkit-transition: all 0.3s ease-out; /* Safari , IE*/
  -moz-transition: all 0.3s ease-out; /* Firefox */
  -o-transition: all 0.3s ease-out; /* Opera */
  transition: all 0.3s ease-out;
}

%opacity-transition {
  -webkit-transition: opacity 0.3s ease-out; /* Safari , IE*/
  -moz-transition: opacity 0.3s ease-out; /* Firefox */
  -o-transition: opacity 0.3s ease-out; /* Opera */
  transition: opacity 0.3s ease-out;
}


//BORDER RADIUS

//all
%border-radius{
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

//smaller radius all
%border-radius-small{
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

//top left
%border-radius-top-left{
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
}

//top right
%border-radius-top-right{
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
}

//bottom left
%border-radius-bottom-left{
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-left-radius: 5px;
}

//bottom right
%border-radius-bottom-right{
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  border-bottom-right-radius: 5px;
}
