/* mixins/library/_arrows.scss */

@mixin arrow($dir: 'left',$size: 75px,$colour: $brand6) {
  width: $size;
  height: $size;

  border-top: ($size / 15) solid $colour;
  border-left: ($size / 15) solid $colour;
  border-right: ($size / 15) solid $colour;
  border-bottom: ($size / 15) solid $colour;

  @if $dir == 'up' or $dir == 'down' or $dir == 'left' {
    border-right: ($size / 15) solid transparent;
  }
  @if $dir == 'up' or $dir == 'right' or $dir == 'left' {
    border-bottom: ($size / 15) solid transparent;
  }
  @if $dir == 'up' or $dir == 'right' {
    transform: rotate(45deg);
  }
  @if $dir == 'down' or $dir == 'left' {
    transform: rotate(-45deg);
  }
  @if $dir == 'down' {
    border-top: ($size / 15) solid transparent;
  }
  @if $dir == 'right' {
    border-left: ($size / 15) solid transparent;
  }
}

@mixin arrow-up {
  width: 75px;
  height: 75px;
  border-top: 5px solid $brand6;
  border-left: 5px solid $brand6;
  
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;

  transform: rotate(45deg);
}

@mixin arrow-down {
  width: 75px;
  height: 75px;
  border-bottom: 5px solid $brand6;
  border-left: 5px solid $brand6;
  
  border-right: 5px solid transparent;
  border-top: 5px solid transparent;

  transform: rotate(-45deg);
}

@mixin arrow-right {
  width: 75px;
  height: 75px;
  border-top: 5px solid $brand6;
  border-right: 5px solid $brand6;
  
  border-left: 5px solid transparent;
  border-bottom: 5px solid transparent;

  transform: rotate(45deg);
}

@mixin arrow-left {
  width: 75px;
  height: 75px;
  border-top: 5px solid $brand6;
  border-left: 5px solid $brand6;
  
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;

  transform: rotate(-45deg);
}