@font-face {
  font-family: 'icomoon--common--typicons';
  src:url('../../fonts/icomoon/icomoon--common/icomoon--common--typicons/fonts/icomoon.eot?-c3zxka');
  src:url('../../fonts/icomoon/icomoon--common/icomoon--common--typicons/fonts/icomoon.eot?#iefix-c3zxka') format('embedded-opentype'),
    url('../../fonts/icomoon/icomoon--common/icomoon--common--typicons/fonts/icomoon.woff?-c3zxka') format('woff'),
    url('../../fonts/icomoon/icomoon--common/icomoon--common--typicons/fonts/icomoon.ttf?-c3zxka') format('truetype'),
    url('../../fonts/icomoon/icomoon--common/icomoon--common--typicons/fonts/icomoon.svg?-c3zxka#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

// @include icon('icomoon--common--typicons');

%icon-times2 {
  @include icon('icomoon--common--typicons');
  content: "\e900";
}
%icon-tick {
  @include icon('icomoon--common--typicons');
  content: "\e901";
}
%icon-plus2 {
  @include icon('icomoon--common--typicons');
  content: "\e902";
}
%icon-minus2 {
  @include icon('icomoon--common--typicons');
  content: "\e903";
}
%icon-chevron-right {
  @include icon('icomoon--common--typicons');
  content: "\e904";
}
%icon-chevron-left {
  @include icon('icomoon--common--typicons');
  content: "\e905";
}
%icon-arrow-right-thick {
  @include icon('icomoon--common--typicons');
  content: "\e906";
}
%icon-arrow-left-thick {
  @include icon('icomoon--common--typicons');
  content: "\e907";
}
%icon-th-small {
  @include icon('icomoon--common--typicons');
  content: "\e908";
}
%icon-th-menu {
  @include icon('icomoon--common--typicons');
  content: "\e909";
}
%icon-th-list2 {
  @include icon('icomoon--common--typicons');
  content: "\e90a";
}
%icon-th-large2 {
  @include icon('icomoon--common--typicons');
  content: "\e90b";
}
%icon-home4 {
  @include icon('icomoon--common--typicons');
  content: "\e90c";
}
%icon-arrow-forward {
  @include icon('icomoon--common--typicons');
  content: "\e90d";
}
%icon-arrow-back {
  @include icon('icomoon--common--typicons');
  content: "\e90e";
}
%icon-location3 {
  @include icon('icomoon--common--typicons');
  content: "\e90f";
}
%icon-link3 {
  @include icon('icomoon--common--typicons');
  content: "\e910";
}
%icon-image3 {
  @include icon('icomoon--common--typicons');
  content: "\e911";
}
%icon-arrow-up-thick {
  @include icon('icomoon--common--typicons');
  content: "\e912";
}
%icon-arrow-down-thick {
  @include icon('icomoon--common--typicons');
  content: "\e913";
}
%icon-delete {
  @include icon('icomoon--common--typicons');
  content: "\e914";
}
%icon-delete-outline {
  @include icon('icomoon--common--typicons');
  content: "\e915";
}
%icon-heart3 {
  @include icon('icomoon--common--typicons');
  content: "\e916";
}
%icon-cancel {
  @include icon('icomoon--common--typicons');
  content: "\e917";
}
%icon-backspace {
  @include icon('icomoon--common--typicons');
  content: "\e918";
}
%icon-attachment2 {
  @include icon('icomoon--common--typicons');
  content: "\e919";
}
%icon-warning3 {
  @include icon('icomoon--common--typicons');
  content: "\e91a";
}
%icon-lock-open {
  @include icon('icomoon--common--typicons');
  content: "\e91b";
}
%icon-lock-closed {
  @include icon('icomoon--common--typicons');
  content: "\e91c";
}
%icon-location-arrow2 {
  @include icon('icomoon--common--typicons');
  content: "\e91d";
}
%icon-info2 {
  @include icon('icomoon--common--typicons');
  content: "\e91e";
}
%icon-user-delete {
  @include icon('icomoon--common--typicons');
  content: "\e91f";
}
%icon-user-add {
  @include icon('icomoon--common--typicons');
  content: "\e920";
}
%icon-group2 {
  @include icon('icomoon--common--typicons');
  content: "\e921";
}
%icon-eye3 {
  @include icon('icomoon--common--typicons');
  content: "\e922";
}
%icon-cog3 {
  @include icon('icomoon--common--typicons');
  content: "\e923";
}
%icon-camera3 {
  @include icon('icomoon--common--typicons');
  content: "\e924";
}
%icon-upload4 {
  @include icon('icomoon--common--typicons');
  content: "\e925";
}
%icon-pin {
  @include icon('icomoon--common--typicons');
  content: "\e926";
}
%icon-key3 {
  @include icon('icomoon--common--typicons');
  content: "\e927";
}
%icon-info-large {
  @include icon('icomoon--common--typicons');
  content: "\e928";
}
%icon-download4 {
  @include icon('icomoon--common--typicons');
  content: "\e929";
}
%icon-zoom {
  @include icon('icomoon--common--typicons');
  content: "\e92a";
}
%icon-zoom-out {
  @include icon('icomoon--common--typicons');
  content: "\e92b";
}
%icon-zoom-in {
  @include icon('icomoon--common--typicons');
  content: "\e92c";
}
%icon-sort-numerically {
  @include icon('icomoon--common--typicons');
  content: "\e92d";
}
%icon-sort-alphabetically {
  @include icon('icomoon--common--typicons');
  content: "\e92e";
}
%icon-input-checked {
  @include icon('icomoon--common--typicons');
  content: "\e92f";
}
%icon-calender {
  @include icon('icomoon--common--typicons');
  content: "\e930";
}
%icon-world {
  @include icon('icomoon--common--typicons');
  content: "\e931";
}
%icon-notes {
  @include icon('icomoon--common--typicons');
  content: "\e932";
}
%icon-code {
  @include icon('icomoon--common--typicons');
  content: "\e933";
}
%icon-spanner {
  @include icon('icomoon--common--typicons');
  content: "\e934";
}
%icon-phone3 {
  @include icon('icomoon--common--typicons');
  content: "\e935";
}
%icon-microphone2 {
  @include icon('icomoon--common--typicons');
  content: "\e936";
}
%icon-flag3 {
  @include icon('icomoon--common--typicons');
  content: "\e937";
}
%icon-social-twitter {
  @include icon('icomoon--common--typicons');
  content: "\e938";
}
%icon-social-facebook {
  @include icon('icomoon--common--typicons');
  content: "\e939";
}
%icon-social-dribbble {
  @include icon('icomoon--common--typicons');
  content: "\e93a";
}
%icon-media-stop {
  @include icon('icomoon--common--typicons');
  content: "\e93b";
}
%icon-media-record {
  @include icon('icomoon--common--typicons');
  content: "\e93c";
}
%icon-media-play {
  @include icon('icomoon--common--typicons');
  content: "\e93d";
}
%icon-media-fast-forward {
  @include icon('icomoon--common--typicons');
  content: "\e93e";
}
%icon-media-eject {
  @include icon('icomoon--common--typicons');
  content: "\e93f";
}
%icon-social-vimeo {
  @include icon('icomoon--common--typicons');
  content: "\e940";
}
%icon-social-tumbler {
  @include icon('icomoon--common--typicons');
  content: "\e941";
}
%icon-social-skype {
  @include icon('icomoon--common--typicons');
  content: "\e942";
}
%icon-social-pinterest {
  @include icon('icomoon--common--typicons');
  content: "\e943";
}
%icon-social-linkedin {
  @include icon('icomoon--common--typicons');
  content: "\e944";
}
%icon-social-github {
  @include icon('icomoon--common--typicons');
  content: "\e945";
}
%icon-social-flickr {
  @include icon('icomoon--common--typicons');
  content: "\e946";
}
