div.notice {
	background-color: $brand3;
	position: relative;
	width: 100%;
	div.message {
		padding: .75em;
		padding-left: .5em;
	}
	a {
		font-size: .7em;
		margin: 0;
		text-decoration: none;
		color: $brand1;
		&:before {
			@extend %icon-warning;
			top: 5px;
			margin-right: 1em;
		}
		&:hover {
			color: $brand2;
		}
		span.level {
			font-weight: bold;
			letter-spacing: 2px;
			text-transform: uppercase;
		}
	}
	&.fixed {
		position: fixed;
		top: 0;
		z-index: 100;
	}
	@include breakpoint($smallest-screen) {
		padding-left: 1em;
	}
	@include breakpoint($small-screen) {
		div.message {
			padding-left: 2em;
			a {
				font-size: .8em;
			}
		}
  }
}