/* _theme-commons.scss */
// bower components
  // kit of misc reusable sass functions : https://github.com/at-import/toolkit
    @import '../bower_components/sass-toolkit/stylesheets/toolkit';
  // simple media queries for sass : https://github.com/at-import/breakpoint
    @import '../bower_components/breakpoint-sass/stylesheets/breakpoint';
  // responsive grid framework : https://github.com/at-import/Singularity
    @import '../bower_components/singularity/stylesheets/singularitygs';
  // values that share the same relationship
  // works with em and px : https://github.com/modularscale/modularscale-sass OR http://www.modularscale.com/
    @import '../bower_components/modular-scale/stylesheets/modularscale';

// settings
  @import '_settings/index';

// mixins
  @import '_mixins/index';
