/* objects/_slider.scss */

.slick-carousel {
  .slick-arrow {
    background: none;
    border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    outline: none;
    display: none !important;
    @include breakpoint($narrow-screen) {
      display: block !important;
    }
    &.slick-prev,
    &.slick-next {
      &:hover {
        cursor: pointer;
      }
      padding: 0;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    &.slick-prev {
      left: 5%;
      &:before {
        @include arrow('left',35px,$light-grey); // $dir, $size, $colour
        left: 100%;
        @include breakpoint($small-screen-max) {
          @include arrow('left',50px,$light-grey); // $dir, $size, $colour
        }
      }
    }
    &.slick-next {
      right: 5%;
      &:before {
        @include arrow('right',35px,$light-grey); // $dir, $size, $colour
        right: 100%;
        @include breakpoint($small-screen-max) {
          @include arrow('right',50px,$light-grey); // $dir, $size, $colour
        }
      }
    }
    &.slick-disabled {
      opacity: 0;
    }
  }

  .slick-list,
  .slick-track,
  .slide.slick-slide {
    height: 100%;
    outline: none;
  }

  .slick-dots {
    display: inline-block !important;
    margin: 10px auto 0;
    padding: 0;
    @include breakpoint($narrow-screen) {
      display: none !important;
    }
    li {
      float: left;
      list-style-type: none;
      margin-right: 10px;
      font-size: ms(-2);
      &:last-child {
        margin-right: 0;
      }
      button {
        background: none;
        border: none;
        background-color: $light-grey;
        border-radius: 50%;
        padding: 5px 12px;
        &:hover {
          background-color: $grey;
        }
      }
      &.slick-active {
        button {
          background-color: tint($xlight-grey,75%);
        }
      }
    }
  }
}