/* _layout.scss */
// contains the design structure styles, including
// responsive design implementation


/*  @sections
========================================================================== */

// ie7 needs a width for box sizing to work.
// box sizing issue que // just make widths for elements for 960wide.
// utilize no-boxizing if it works.

/*  @main layout regions
========================================================================== */

.container {
  position: relative;
  max-width: $wide-screen;
  margin: 0 auto;
  padding: 0 ms();
  @include clearfix;
  @include breakpoint($small-screen-max) {
    padding: 0 ms(2);
  }
  @include breakpoint($narrow-screen $odd-screen-max) {
    padding: 0 6.38298%;
  }
  @include breakpoint($medium-screen) {
    padding: 0 ms(-1);
  }
}

/*  @main layout regions
========================================================================== */

@import 'layout/header';
@import 'layout/content';
@import 'layout/footer';
























