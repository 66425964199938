section.gallery {
  // height: 100vh;
  width: 100%;
  background-color: $brand1;
  float: left;
  @include breakpoint($medium-screen) {
    position: fixed;
    width: 50%;
  }
  .grid {
    height: calc(100vh / 3);
    position: relative;
    overflow: hidden;
    .background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: scale(1);
      @extend %transition;
      .image {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        img {
          position: absolute;
          left: -100%;
          right: -100%;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 100%;
          max-width: none;
          min-width: 100%;
          -webkit-filter: contrast(150%) brightness(60%);
          filter: contrast(150%) brightness(60%);
          opacity: .5;
          @extend %transition;
        }
        &:before {
          // content: none;
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba($brand1, 1);
          transition: all .3s linear;
          opacity: .4;
        }
      }
    }
    &.non-interactive {
      display: block;
      background-color: white;
      .background {
        padding: 1.5em;
        .image {
          background-size: contain;
          &:before {
            content: none;
          }
        }
      }
      @include breakpoint($narrow-screen) {
        display: none;
      }
    }
    &:not(.non-interactive) {
      div.hover-title {
        position: absolute;
        z-index: 5;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        @extend %transition;
        display: inline-block;
        vertical-align: middle;
        p.title {
          position: relative;
          height: 100%;
          width: 100%;
          margin: 0;
          span.text {
            color: $brand4;
            font-size: 1.2em;
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            padding: .5em 0;
            border-top: 1px solid $brand4;
            border-bottom: 1px solid $brand4;
            width: 80%;
            p {
              display: inline-block;
              margin: 0;
              color: $brand4;
            }
            @include breakpoint($narrow-screen) {
              font-size: 1.5em;
            }
          }
        }
      }
      #our-services .background .image:before {
        @include breakpoint($narrow-screen) {
          background: none;
        }
      }
    }
  }
}

body:not(.mobile-device) {
  section.gallery {
    .grid {
      &:not(.non-interactive) {
        &:hover {
          cursor: pointer;
          div.hover-title {
            opacity: 1;
            background-color: rgba($brand6, .6);
            @extend %transition;
          }
          .background {
            transform: scale(1.3);
            @extend %transition;
            .image {
              @extend %transition;
              img {
                -webkit-filter: contrast(100%) brightness(100%);
                filter: contrast(100%) brightness(100%);
                opacity: 1;
                transform: scale(1.25);
                @extend %transition;
              }
              &:before {
                opacity: 1;
                background: none;
              }
            }
          }
        }
      }
    }
  }
}
body.mobile-device {
  section.gallery {
    .grid {
      &:not(.non-interactive) {
        div.hover-title {
          opacity: 1;
          background-color: rgba($brand6, .4);
          p.title {
            span.text {
              border: none;
              font-size: 1.3em;
            }
          }
        }
        .background {
          .image {
            img {
              -webkit-filter: contrast(100%) brightness(100%);
              filter: contrast(100%) brightness(100%);
              opacity: 1;
              @extend %transition;
            }
            &:before {
              opacity: 1;
              background: none;
            }
          }
        }
      }
    }
  }
}