@mixin btn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  outline: none;
  padding: ms(-2) ms();
  border-radius: 25px;
  margin-right: ms();
  background: #fff;
  border: 1px solid $brand1;
  text-decoration: none;
  font-size: ms(-1);
  color: $dark-grey;
  font-weight: $medium;
  line-height: normal;
  @extend %transition;

  @include breakpoint($narrow-screen) {
    padding: ms(-1) ms(2);
  }

  & > * {
    position: relative;
  }

  &:hover, 
  &:active, 
  &:focus {
    background: $dark-grey !important;
    color: #fff !important;
    border: 1px solid $brand1;
  }
}
