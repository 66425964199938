//////////////////////////////
// Find gutter style
//
// Finds the gutter style that you are working with.
// From Singularity
// Must be using Breakpoint to work properly
//////////////////////////////
@function find-gutter-style($user-gutter-styles: null) {

  // We supply a default if the user hasn't set any gutters and hasn't passed in a custom column set
  // The default supplied is based on original Susy options
  $Gutters-Styles: sgs-get('gutter styles');

  $Found: find-object($Gutters-Styles, $user-gutter-styles);

  @if length($Found) == 1 {
    $Found: nth($Found, 1);
  }

  @return $Found;
}