/* _wireframe.scss */
// @imports all SASS partials from /wireframe folder.
// contains temporary styles for website use in pre-development.
// before beginning development, comment out this file in _components.scss

// @import 'wireframe/layout-wireframe';
// @import 'wireframe/elements-wireframe';

.divider {
	height: 1px;
	width: 20%;
	display: block;
	margin: 25px auto;
  @include breakpoint($small-screen-max) {
    width: 10%;
  }
	&.light {
		background-color: $light-grey;
	}
	&.dark {
		background-color: $xdark-grey;
	}
  &.hidden {
    display: none;
  }
}

h2 {
	color: $xdark-grey;
}

footer {
  background-color: white;
}


