@font-face {
  font-family: 'icomoon--arrows';
  src:url('../../fonts/icomoon/icomoon--arrows/fonts/icomoon.eot?-c3zxka');
  src:url('../../fonts/icomoon/icomoon--arrows/fonts/icomoon.eot?#iefix-c3zxka') format('embedded-opentype'),
    url('../../fonts/icomoon/icomoon--arrows/fonts/icomoon.woff?-c3zxka') format('woff'),
    url('../../fonts/icomoon/icomoon--arrows/fonts/icomoon.ttf?-c3zxka') format('truetype'),
    url('../../fonts/icomoon/icomoon--arrows/fonts/icomoon.svg?-c3zxka#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

// @include icon('icomoon--arrows');

%icon-arrow-outline-down {
  @include icon('icomoon--arrows');
  content: "\e900";
}
%icon-arrow-outline-left {
  @include icon('icomoon--arrows');
  content: "\e901";
}
%icon-arrow-outline-right {
  @include icon('icomoon--arrows');
  content: "\e902";
}
%icon-arrow-outline-up {
  @include icon('icomoon--arrows');
  content: "\e903";
}
%icon-arrow-thick-down {
  @include icon('icomoon--arrows');
  content: "\e904";
}
%icon-arrow-thick-left {
  @include icon('icomoon--arrows');
  content: "\e905";
}
%icon-arrow-thick-right {
  @include icon('icomoon--arrows');
  content: "\e906";
}
%icon-arrow-thick-up {
  @include icon('icomoon--arrows');
  content: "\e907";
}
%icon-arrow-thin-down {
  @include icon('icomoon--arrows');
  content: "\e908";
}
%icon-arrow-thin-left {
  @include icon('icomoon--arrows');
  content: "\e909";
}
%icon-arrow-thin-right {
  @include icon('icomoon--arrows');
  content: "\e90a";
}
%icon-arrow-thin-up {
  @include icon('icomoon--arrows');
  content: "\e90b";
}
%icon-cheveron-down {
  @include icon('icomoon--arrows');
  content: "\e90c";
}
%icon-cheveron-left {
  @include icon('icomoon--arrows');
  content: "\e90d";
}
%icon-cheveron-outline-down {
  @include icon('icomoon--arrows');
  content: "\e90e";
}
%icon-cheveron-outline-left {
  @include icon('icomoon--arrows');
  content: "\e90f";
}
%icon-cheveron-outline-right {
  @include icon('icomoon--arrows');
  content: "\e910";
}
%icon-cheveron-outline-up {
  @include icon('icomoon--arrows');
  content: "\e911";
}
%icon-cheveron-right {
  @include icon('icomoon--arrows');
  content: "\e912";
}
%icon-cheveron-up {
  @include icon('icomoon--arrows');
  content: "\e913";
}
%icon-arrow_back {
  @include icon('icomoon--arrows');
  content: "\e5c4";
}
%icon-arrow_downward {
  @include icon('icomoon--arrows');
  content: "\e5db";
}
%icon-arrow_forward {
  @include icon('icomoon--arrows');
  content: "\e5c8";
}
%icon-arrow_upward {
  @include icon('icomoon--arrows');
  content: "\e5d8";
}
%icon-keyboard_arrow_down {
  @include icon('icomoon--arrows');
  content: "\e313";
}
%icon-keyboard_arrow_left {
  @include icon('icomoon--arrows');
  content: "\e314";
}
%icon-keyboard_arrow_right {
  @include icon('icomoon--arrows');
  content: "\e315";
}
%icon-keyboard_arrow_up {
  @include icon('icomoon--arrows');
  content: "\e316";
}
%icon-chevron-left {
  @include icon('icomoon--arrows');
  content: "\f053";
}
%icon-chevron-right {
  @include icon('icomoon--arrows');
  content: "\f054";
}
%icon-arrow-left {
  @include icon('icomoon--arrows');
  content: "\f060";
}
%icon-arrow-right {
  @include icon('icomoon--arrows');
  content: "\f061";
}
%icon-arrow-up {
  @include icon('icomoon--arrows');
  content: "\f062";
}
%icon-arrow-down {
  @include icon('icomoon--arrows');
  content: "\f063";
}
%icon-chevron-up {
  @include icon('icomoon--arrows');
  content: "\f077";
}
%icon-chevron-down {
  @include icon('icomoon--arrows');
  content: "\f078";
}
%icon-arrow-circle-left {
  @include icon('icomoon--arrows');
  content: "\f0a8";
}
%icon-arrow-circle-right {
  @include icon('icomoon--arrows');
  content: "\f0a9";
}
%icon-arrow-circle-up {
  @include icon('icomoon--arrows');
  content: "\f0aa";
}
%icon-arrow-circle-down {
  @include icon('icomoon--arrows');
  content: "\f0ab";
}
%icon-caret-down {
  @include icon('icomoon--arrows');
  content: "\f0d7";
}
%icon-caret-up {
  @include icon('icomoon--arrows');
  content: "\f0d8";
}
%icon-caret-left {
  @include icon('icomoon--arrows');
  content: "\f0d9";
}
%icon-caret-right {
  @include icon('icomoon--arrows');
  content: "\f0da";
}
%icon-angle-double-left {
  @include icon('icomoon--arrows');
  content: "\f100";
}
%icon-angle-double-right {
  @include icon('icomoon--arrows');
  content: "\f101";
}
%icon-angle-double-up {
  @include icon('icomoon--arrows');
  content: "\f102";
}
%icon-angle-double-down {
  @include icon('icomoon--arrows');
  content: "\f103";
}
%icon-angle-left {
  @include icon('icomoon--arrows');
  content: "\f104";
}
%icon-angle-right {
  @include icon('icomoon--arrows');
  content: "\f105";
}
%icon-angle-up {
  @include icon('icomoon--arrows');
  content: "\f106";
}
%icon-angle-down {
  @include icon('icomoon--arrows');
  content: "\f107";
}
%icon-long-arrow-down {
  @include icon('icomoon--arrows');
  content: "\f175";
}
%icon-long-arrow-up {
  @include icon('icomoon--arrows');
  content: "\f176";
}
%icon-long-arrow-left {
  @include icon('icomoon--arrows');
  content: "\f177";
}
%icon-long-arrow-right {
  @include icon('icomoon--arrows');
  content: "\f178";
}
%icon-undo2 {
  @include icon('icomoon--arrows');
  content: "\e967";
}
%icon-redo2 {
  @include icon('icomoon--arrows');
  content: "\e968";
}
%icon-forward {
  @include icon('icomoon--arrows');
  content: "\e969";
}
%icon-reply {
  @include icon('icomoon--arrows');
  content: "\e96a";
}
%icon-arrow-up-left {
  @include icon('icomoon--arrows');
  content: "\ea31";
}
%icon-arrow-up2 {
  @include icon('icomoon--arrows');
  content: "\ea32";
}
%icon-arrow-up-right {
  @include icon('icomoon--arrows');
  content: "\ea33";
}
%icon-arrow-right2 {
  @include icon('icomoon--arrows');
  content: "\ea34";
}
%icon-arrow-down-right {
  @include icon('icomoon--arrows');
  content: "\ea35";
}
%icon-arrow-down2 {
  @include icon('icomoon--arrows');
  content: "\ea36";
}
%icon-arrow-down-left {
  @include icon('icomoon--arrows');
  content: "\ea37";
}
%icon-arrow-left2 {
  @include icon('icomoon--arrows');
  content: "\ea38";
}
%icon-arrow-up-left2 {
  @include icon('icomoon--arrows');
  content: "\ea39";
}
%icon-arrow-up22 {
  @include icon('icomoon--arrows');
  content: "\ea3a";
}
%icon-arrow-up-right2 {
  @include icon('icomoon--arrows');
  content: "\ea3b";
}
%icon-arrow-right22 {
  @include icon('icomoon--arrows');
  content: "\ea3c";
}
%icon-arrow-down-right2 {
  @include icon('icomoon--arrows');
  content: "\ea3d";
}
%icon-arrow-down22 {
  @include icon('icomoon--arrows');
  content: "\ea3e";
}
%icon-arrow-down-left2 {
  @include icon('icomoon--arrows');
  content: "\ea3f";
}
%icon-arrow-left22 {
  @include icon('icomoon--arrows');
  content: "\ea40";
}
%icon-circle-up {
  @include icon('icomoon--arrows');
  content: "\ea41";
}
%icon-circle-right {
  @include icon('icomoon--arrows');
  content: "\ea42";
}
%icon-circle-down {
  @include icon('icomoon--arrows');
  content: "\ea43";
}
%icon-circle-left {
  @include icon('icomoon--arrows');
  content: "\ea44";
}