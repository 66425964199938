/* objects/_button.scss */

// global button styles

/* =============================================================================
    REUSABLE SITEWIDE BUTTONS
   ========================================================================== */

.btn {
  @include btn;
}

//PRIMARY
.btn-primary {
  @include btn;

  &:hover, 
  &:active, 
  &:focus {
    background: $dark-grey !important;
    color: #fff !important;
    border: 1px solid $brand5;
  }
}

//SECONDARY
.btn-secondary {
  @include btn;
  border: 1px solid $brand2;
  color: $brand2 !important;

  &:hover, 
  &:active, 
  &:focus {
    background: $brand2 !important;
    color: #fff !important;
    border: 1px solid $brand2;
  }
}

//BUTTON WITH ARROW
.btn-arrow {
  @extend .btn-primary;

  &:after {
    @extend %icon-arrow_right !optional;
    float: right;
    top: 2px;
    margin-left: .5em;
    margin-right: 0;
  }
}

