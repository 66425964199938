/* layout/_content.scss */

/* @content section */

.sidebar {
	width: 100%;
	float: none;
  @include breakpoint($small-screen-max) {
    float: right;
  }
  @include breakpoint($odd-screen) {
    width: 50%;
    float: left;
  }
}

.event {
  .sidebar {
    &.side-right {
      padding-right: 0;
      @include breakpoint($standard-screen) {
        padding-right: 5%;
      }
      @include breakpoint($wide-screen) {
        padding-right: 10%;
      }
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  &.col-1 {
    flex: calc((100% / 12) * 1);
    max-width: calc((100% / 12) * 1);
  }
  &.col-2 {
    flex: calc((100% / 12) * 2);
    max-width: calc((100% / 12) * 2);
  }
  &.col-3 {
    flex: calc((100% / 12) * 3);
    max-width: calc((100% / 12) * 3);
  }
  &.col-4 {
    flex: calc((100% / 12) * 4);
    max-width: calc((100% / 12) * 4);
  }
  &.col-5 {
    flex: calc((100% / 12) * 5);
    max-width: calc((100% / 12) * 5);
  }
  &.col-6 {
    flex: calc((100% / 12) * 6);
    max-width: calc((100% / 12) * 6);
  }
  &.col-7 {
    flex: calc((100% / 12) * 7);
    max-width: calc((100% / 12) * 7);
  }
  &.col-8 {
    flex: calc((100% / 12) * 8);
    max-width: calc((100% / 12) * 8);
  }
  &.col-9 {
    flex: calc((100% / 12) * 9);
    max-width: calc((100% / 12) * 9);
  }
  &.col-10 {
    flex: calc((100% / 12) * 10);
    max-width: calc((100% / 12) * 10);
  }
  &.col-11 {
    flex: calc((100% / 12) * 11);
    max-width: calc((100% / 12) * 11);
  }
  &.col-12 {
    flex: calc((100% / 12) * 12);
    max-width: calc((100% / 12) * 12);
  }
}

.block {
  width: 100%;
  height: 100%;
  &.darkgrey {
    background-color: darkgrey;
  }
  &.grey {
    background-color: grey;
  }
  &.brand-colour1 {
    background-color: $brand1;
  }
  &.brand-colour2 {
    background-color: $brand2;
  }
  &.brand-colour3 {
    background-color: $brand3;
  }
}


