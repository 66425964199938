@font-face {
  font-family: 'caviar_dreamsregular';
  src: url('../../fonts/caviardreams/caviardreams/caviardreams-webfont.woff2') format('woff2'),
       url('../../fonts/caviardreams/caviardreams/caviardreams-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'caviar_dreamsbold';
  src: url('../../fonts/caviardreams/caviardreams_bold/caviardreams_bold-webfont.woff2') format('woff2'),
       url('../../fonts/caviardreams/caviardreams_bold/caviardreams_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'caviar_dreamsbold_italic';
  src: url('../../fonts/caviardreams/caviardreams_bolditalic/caviardreams_bolditalic-webfont.woff2') format('woff2'),
       url('../../fonts/caviardreams/caviardreams_bolditalic/caviardreams_bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'caviar_dreamsitalic';
  src: url('../../fonts/caviardreams/caviardreams_italic/caviardreams_italic-webfont.woff2') format('woff2'),
       url('../../fonts/caviardreams/caviardreams_italic/caviardreams_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}