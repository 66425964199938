// @type settings    ==============================================

// modular scale settings are located in typography/_modular-scale.scss
$xlight: 100;
$lightest: 100;
$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;
$boldest: 900;
$xbold: 900;

// Brand Fonts
$font1: 'caviar_dreamsregular', sans-serif;
  $font1-bold: 'caviar_dreamsbold', sans-serif;
  $font1-bolditalic: 'caviar_dreamsbold_italic', sans-serif;
  $font1-italic: 'caviar_dreamsitalic', sans-serif;
$font2: 'Montserrat', sans-serif;
$font3: 'nexa_light', sans-serif;
  $font3-bold: 'nexa_bold', sans-serif;


