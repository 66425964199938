@font-face {
  font-family: 'nexa_light';
  src: url('../../fonts/nexa_light/nexa_light-webfont.woff2') format('woff2'),
       url('../../fonts/nexa_light/nexa_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nexa_bold';
  src: url('../../fonts/nexa_bold/nexa_bold-webfont.woff2') format('woff2'),
       url('../../fonts/nexa_bold/nexa_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}