/* components/_brand.scss */

// brand
div.logo {
  @include center;
  width: 70%;
  @include breakpoint($narrow-screen) {
    width: 60%;
  }
  @include breakpoint($odd-screen) {
    width: 80%;
  }
  @include breakpoint($medium-screen) {
    width: 60%;
  }
  @include breakpoint($wide-screen) {
    width: 45%;
  }
  &.hidden {
    display: none;
  }
}

div.slogan {
  @include center;
  margin-top: 20px;
  p {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 0 20px;
    color: $brand1;
    font-weight: 400;
    span.slogan-container {
      padding: 0;
      font-size: .8em;
      span {
        display: inline-block;
        width: auto;
        &:after {
          content: " | ";
          padding: 0 10px;
        }
        &:last-child:after {
          content: '';
          padding: 0;
        }
      }
    }
  }
  @include breakpoint($smallest-screen) {
    margin-top: 40px;
    p {
      span.slogan-container {
        font-size: .9em;
      }
    }
  }
  @include breakpoint($small-screen) {
    p {
      span.slogan-container {
        font-size: 1em;
      }
    }
  }
}