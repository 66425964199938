@mixin underline($background: null, $color: null, $clear-descenders: null, $distance: null, $width: null, $extend: null) {
  $background: if($background != null, $background, toolkit-get('underline background'));
  $color: if($color != null, $color, toolkit-get('underline color'));
  $clear-descenders: if($clear-descenders != null, $clear-descenders, toolkit-get('underline clear descenders'));
  $distance: if($distance != null, $distance, toolkit-get('underline distance'));
  $width: if($width != null, $width, toolkit-get('underline width'));
  $extend: if($extend != null, $extend, toolkit-get('underline extend'));

  $PX: .0625em;

  @include underline-core($extend);

  @if mixin-exists(background-image) and function-exists(linear-gradient) {
    @include background-image(
      linear-gradient(bottom, $background 75%, $color 75%)
    );
  }
  @else {
    background-image: linear-gradient(to top, $background 75%, $color 75%);
  }
  background-size: (2 * $PX) ((1 + $width) * $PX);
  background-position: 0 (($width) * $PX) + $distance;

  @if $clear-descenders {
    text-shadow: (1 * $PX) (1 * $PX) 0 $background, (-1 * $PX) (-1 * $PX) 0 $background, (1 * $PX) (-1 * $PX) 0 $background, (-1 * $PX) (1 * $PX) 0 $background;
  }
}

@mixin underline-core($extend: null) {
  $extend: if($extend != null, $extend, toolkit-get('underline extend'));

  @if $extend {
    @include dynamic-extend('underline') {
      @include underline-core(false);
    }
  }
  @else {
    text-decoration: none;
    background-repeat: repeat-x;
  }
}
