/* layout/_header.scss */

/* @header section */

body.mobile {
  header {
    .mobilemenu {
      position: relative;
      background-color: white;
      display: inline-block;
      width: 100%;
    }
    .head {
      position: fixed;
      display: inline-block;
      right: 0;
      z-index: 999999;
    }
  }
}

header {
  position: relative;
  overflow: hidden;
  // min-height: 700px;
  // background-color: rgba($brand6, .2);
  // background: url('../../images/temp/pexels/pexels-photo-613056.jpeg') no-repeat fixed center center;
  // background-size: cover;

  .mobilemenu {
    background-color: rgba(white,.6);
  }
}