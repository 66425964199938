//////////////////////////////
// Default Settings
//////////////////////////////
$Singularity-Settings: (
  'grids': (-1px: 12),
  'gutters': (-1px: .25),
  'gutter styles': (-1px: 'opposite'),
  'output': 'isolation',
  'direction': 'ltr',
  'mobile first': true,
  'include border box': false,
  'include clearfix': false,
  'background grid color': chocolate,
  'asymmetric output': 'isolation',
  'calc include min-width': true,
  'debug': false
);

//////////////////////////////
// User Settings
//////////////////////////////
$singularity: () !default;

//////////////////////////////
// Import General Helpers
//////////////////////////////
@import "singularitygs/helpers";

//////////////////////////////
// Import Language Helpers
//////////////////////////////
@import "singularitygs/language";

//////////////////////////////
// Import Math
//////////////////////////////
@import "singularitygs/math";

//////////////////////////////
// Import Grid Helpers
//////////////////////////////
@import "singularitygs/grids";

//////////////////////////////
// Import Gutters Helpers
//////////////////////////////
@import "singularitygs/gutters";

//////////////////////////////
// Import Gutter Styles
//////////////////////////////
@import "singularitygs/gutter-styles";

//////////////////////////////
// Import APIs
//////////////////////////////
@import "singularitygs/api";