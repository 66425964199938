/* navigation/_main-nav.scss */

@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

nav.main-navigation {
  text-align: center;
  float: left;
  @include breakpoint($small-screen) {
    float: none;
  }
	ul {
	  display: inline-block;
    padding: 0;
	  li {
	    position: relative;
	    float: left;
	    overflow: hidden;
      color: $brand5;
      padding: 10px 15px;

      @include breakpoint($odd-screen) {
        // padding: 10px 10px;
      }
      @include breakpoint($medium-screen) {
        padding: 10px 25px;
      }

	    * {
	      -webkit-box-sizing: border-box;
	      box-sizing: border-box;
	    }
	  }
	}
}

body.mobile {
  .mobilemenu-controller {
    &:before {
      @extend %icon-th-menu !optional;
      @extend %transition;
      font-size: 2em;
      position: absolute;
      right: 10px;
      display: block;
      top: 50%;
      background-color: white;
      border-radius: 50%;
      padding: 10px 13px;

      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    &:hover {
      &:before {
        color: $light-grey;
      }
    }
  }
  nav.main-navigation {
    position: fixed;
    // top: 45px;
    top: 6.5%;
    // right: 45px;
    right: 12%;
    z-index: -1;
    margin: auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    // width: calc(70% - 50px);
    @extend %transition;
    ul {
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 50%;
    }
    &.open {
      background-color: white;
      top: -50%;
      bottom: -50%;
      right: -50%;
      // left: -50%;
      width: 200%;
      height: 200%;
      margin: 0;
      overflow-x: hidden; 
      overflow-y: auto;
      @extend %transition;
      ul {
        display: block;
        li {
          float: left;
          width: 100%;
          font-size: ms();
          margin-top: 0 !important;
        }
      }
    }
  }
}