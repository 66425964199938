@mixin rtl($property, $value) {
  $_property: $property;
  #{$property}: $value;

  @if length($value) == 4 {
    [dir="rtl"] & {
      #{$property}: nth($value, 1) nth($value, 4) nth($value, 3) nth($value, 2);
    }
  }
  @else {
    @if (str-index($property, 'left')) {
      $property: str-slice($property, 1, str-index($property, 'left') - 1) + 'right';
    }
    @else if (str-index($property, 'right')) {
      $property: str-slice($property, 1, str-index($property, 'right') - 1) + 'left';
    }

    @if $property != $_property {
      [dir="rtl"] & {
        #{$property}: $value;
      }
    }
  }
}