// Vertically center anything, literally anything.
// http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
@mixin vertical-center($midpoint: null, $extend: null) {
  $midpoint: if($midpoint != null, $midpoint, toolkit-get('vertical midpoint'));
  $extend: if($extend != null, $extend, toolkit-get('vertical extend'));

  top: $midpoint;
  @include vertical-center-core($extend);
}

@mixin vertical-center-core($extend: null) {
  $extend: if($extend != null, $extend, toolkit-get('vertical extend'));
  @if $extend {
    @include dynamic-extend('vertical center') {
      @include vertical-center-core($extend: false);
    }
  }
  @else {
    position: relative;
    @if mixin-exists(transform) {
      @include transform(translateY(-50%));
    } @else {
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

//////////////////////////////
// Horizontal Center
//////////////////////////////
@mixin horizontal-center($midpoint: null, $fixed: null, $extend: null) {
  $fixed: if($fixed != null, $fixed, toolkit-get('horizontal fixed'));
  $extend: if($extend != null, $extend, toolkit-get('horizontal extend'));

  @if $fixed {
    $midpoint: if($midpoint != null, $midpoint, toolkit-get('horizontal fixed midpoint'));
  }
  @else {
    $midpoint: if($midpoint != null, $midpoint, toolkit-get('horizontal midpoint'));
  }

  @if $midpoint != 0% {
    left: $midpoint;
  }

  @include horizontal-center-core($fixed, $extend);
}

@mixin horizontal-center-core($fixed: null, $extend: null) {
  $fixed: if($fixed != null, $fixed, toolkit-get('horizontal fixed'));
  $extend: if($extend != null, $extend, toolkit-get('horizontal extend'));

  @if $extend {
    @if $fixed {
      @include dynamic-extend('horizontal center fixed') {
        @include horizontal-center-core(true, false);
      }
    }
    @else {
      @include dynamic-extend('horizontal center') {
        @include horizontal-center-core(false, false);
      }
    }
  }
  @else {
    @if $fixed {
      position: fixed;
      @if mixin-exists(transform) {
        @include transform(translateX(-50%));
      } @else {
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
    @else {
      position: relative;
      margin: 0 auto;
    }
  }
}

//////////////////////////////
// Absolute Center
//////////////////////////////
@mixin absolute-center($vertical: null, $horizontal: null, $fixed: null, $extend: null) {
  $vertical: if($vertical != null, $vertical, toolkit-get('absolute center vertical midpoint'));
  $fixed: if($fixed != null, $fixed, toolkit-get('absolute center fixed'));
  $extend: if($extend != null, $extend, toolkit-get('absolute center extend'));

  @if $fixed {
    $horizontal: if($horizontal != null, $horizontal, toolkit-get('absolute center fixed horizontal midpoint'));
  }
  @else {
    $horizontal: if($horizontal != null, $horizontal, toolkit-get('absolute center horizontal midpoint'));
  }

  top: $vertical;
  @if $horizontal != 0% {
    left: $horizontal;
  }

  @include absolute-center-core($fixed, $extend);
}

@mixin absolute-center-core($fixed: null, $extend: null) {
  $fixed: if($fixed != null, $fixed, toolkit-get('absolute center fixed'));
  $extend: if($extend != null, $extend, toolkit-get('absolute center extend'));

  @if $extend {
    @if $fixed {
      @include dynamic-extend('absolute center fixed') {
        @include absolute-center-core(true, false);
      }
    }
    @else {
      @include dynamic-extend('absolute center') {
        @include absolute-center-core(false, false);
      }
    }
  }
  @else {
    @if $fixed {
      position: fixed;
      @if mixin-exists(transform) {
        @include transform(translateY(-50%) translateX(-50%));
      } @else {
        -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-50%) translateX(-50%);
      }
    }
    @else {
      position: relative;
      @if mixin-exists(transform) {
        @include transform(translateY(-50%));
      } @else {
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      margin: 0 auto;
    }
  }

}