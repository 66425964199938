/* _objects.scss */
// contains generic, underlying design patterns and their extensions.
// eg. basic styles for all sidebar blocks or list displays of content

@import 'objects/buttons';
@import 'objects/media-embed';
@import 'objects/content-styles';
@import 'objects/loader';
@import 'objects/titles';
@import 'objects/slider';
@import 'objects/window-measurements';
@import 'objects/messages';

body {
  font-family: $font2;
  font-weight: 200;
  @include no-drag-image;
  &.fixed {
    position: fixed;
    overflow: hidden;
  }
}

header {
  display: block;
  width: 100%;

  h1 {
    float: left;
    margin: 0;
    margin-top: calc((75px - 39px) / 2);
    opacity: 1;
    text-align: left;
    padding-left: 25px;
    @extend %transition;
    &.hide {
      opacity: 0;
      @extend %transition;
    }
  }
}

img.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

@media(device-width: 768px) and (device-height: 1024px){
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
}


