body.open-details {
  height: 100vh;
  overflow: hidden;
  section.introduction {
    display: none;
  }
  section.photo-details {
    position: fixed;
  }
}
section.photo-details {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -10;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  &.open {
    visibility: visible;
    opacity: 1;
    z-index: 101;
  }
  div.photo,
  div.detail-container {
    float: left;
    height: 65vh;
    width: 100%;
    @include breakpoint($medium-screen) {
      width: 50%;
      height: 100vh;
    }
  }
  div.photo {
    position: relative;
    background-color: $brand1;
    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  div.detail-container {
    position: relative;
    background-color: white;
    height: 35vh;
    overflow: scroll;
    div.close {
      position: absolute;
      right: 0;
      top: 25px;
      opacity: .5;
      text-indent: -3000px;
      @extend %opacity-transition;
      &:hover {
        opacity: 1;
        cursor: pointer;
        @extend %opacity-transition;
      }
      &:before {
        font-size: 2em;
        border: 2px solid $brand5;
        border-radius: 50%;
        padding: 5px;
        color: $brand5;
        @extend %icon-times2;
        text-indent: 0;
      }
    }
    div.details {
      padding: 0 25px;
      margin-top: 25px;
      p.title {
        font-size: 1.5em;
        margin-bottom: 0.2em;
        font-weight: 400;
      }
      div.decor {
        width: 40%;
        height: 5px;
      }
      p.desc {
        margin-top: 30px;
        font-size: .9em;
        span {
          display: inline-block;
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      span.company-name {
        font-style: italic;
        font-weight: 400;
      }
      ul {
        li {
          p {
            margin: 0;
          }
        }
      }
    }
    @include breakpoint($medium-screen) {
      height: 100vh;
      div.close {
        top: 50px;
        right: 40px;
      }
      div.details {
        padding: 0 50px;
        margin-top: 0;
        p.title {
          font-size: 2em;
        }
        div.decor {
          height: 7px;
        }
        p.desc {
          font-size: 1em;
        }
      }
    }
  }
}