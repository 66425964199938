// ============== _base.scss
// _base.scss contains 3rd party imports, custom mixins and settings
// import this file if you need to create another standalone stylesheet for
// for the theme so you can utilize all tools and settings

// import once
  // box sizing : https://github.com/inuitcss/generic.box-sizing
    @import '../bower_components/inuit-box-sizing/generic.box-sizing';
  // normalize : https://github.com/inuitcss/generic.normalize
    @import '../bower_components/inuit-normalize.css/generic.normalize';

// import commons
  @import 'common';

// @authors unclassed element styles
  // _elements.scss contains authors unclassed HTML element styles such as h1, h2, table, etc
    @import 'elements';
