/* objects/_window-measurements.scss */

.window-measurements {
  position: fixed;
  top: 0;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 1em;
  font-size: 0.8em;
  border-bottom-right-radius: 10px;
  p {
  	margin-bottom: 0;
  	strong {
  		letter-spacing: 1px;
  	}
  }
}

.breakpoints {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  z-index: 10000;
  p {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.6);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    &.break .value {
      &:after {
        content: 'small-screen = #{$small-screen} / #{$small-screen--em}';
        padding-left: 10px;
        @include breakpoint($small-screen-max) {
          content: 'small-screen-max = #{$small-screen-max} / #{$small-screen-max--em}';
        }

        @include breakpoint($narrow-screen) {
          content: 'narrow-screen = #{$narrow-screen} / #{$narrow-screen--em}';
        }
        @include breakpoint($narrow-screen-max) {
          content: 'narrow-screen-max = #{$narrow-screen-max} / #{$narrow-screen-max--em}';
        }

        @include breakpoint($odd-screen) {
          content: 'odd-screen = #{$odd-screen} / #{$odd-screen--em}';
        }
        @include breakpoint($odd-screen-max) {
          content: 'odd-screen-max = #{$odd-screen-max} / #{$odd-screen-max--em}';
        }

        @include breakpoint($medium-screen) {
          content: 'medium-screen = #{$medium-screen} / #{$medium-screen--em}';
        }
        @include breakpoint($medium-screen-max) {
          content: 'medium-screen-max = #{$medium-screen-max} / #{$medium-screen-max--em}';
        }

        @include breakpoint($standard-screen) {
          content: 'standard-screen = #{$standard-screen} / #{$standard-screen--em}';
        }
        @include breakpoint($standard-screen-max) {
          content: 'standard-screen-max = #{$standard-screen-max} / #{$standard-screen-max--em}';
        }

        @include breakpoint($wide-screen) {
          content: 'wide-screen = #{$wide-screen} / #{$wide-screen--em}';
        }
        @include breakpoint($wide-screen-max) {
          content: 'wide-screen-max = #{$wide-screen-max} / #{$wide-screen-max--em}';
        }

        @include breakpoint($super-wide-screen) {
          content: 'super-wide-screen = #{$super-wide-screen} / #{$super-wide-screen--em}';
        }
      }
    }
    &.prev .value {
      &:after {
        content: 'small-screen-max';
        padding-left: 10px;
        @include breakpoint($small-screen-max) {
          content: 'narrow-screen';
        }

        @include breakpoint($narrow-screen) {
          content: 'odd-screen';
        }

        @include breakpoint($odd-screen) {
          content: 'medium-screen';
        }

        @include breakpoint($medium-screen) {
          content: 'standard-screen';
        }

        @include breakpoint($standard-screen) {
          content: 'wide-screen';
        }

        @include breakpoint($wide-screen) {
          content: 'super-wide-screen';
        }

        @include breakpoint($super-wide-screen) {
          content: '';
        }
      }
    }
  }
}
