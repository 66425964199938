@mixin saturate($value: 3) {
	filter: saturate($value);
}
@mixin grayscale($value: 100%) {
	filter: grayscale($value); 
}
@mixin contrast($value: 160%) {
	filter: contrast($value); 
}
@mixin brightness($value: .25) {
	filter: brightness($value); 
}
@mixin blur($value: 3px) {
	filter: blur($value); 
}
@mixin invert($value: 100%) {
	filter: invert($value); 
}
@mixin sepia($value: 100%) {
	filter: sepia($value); 
}
@mixin huerotate($value: 180deg) {
	filter: hue-rotate($value); 
}
@mixin opacity($value: 50%) {
	filter: opacity($value); 
}