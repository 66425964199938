// SVG with PNG fallback for icon backgrounds
@mixin no-svg { .no-svg & { @content; } } // might need to remove the ";"

@mixin svg-bkgd ($filename, $folder: '', $extension: '.png') {
  @if $folder != '' {
    $filename: $folder + '/' + $filename;
  }
  background-image: image-url($filename + '.svg', false, false);
  background-repeat: no-repeat;
  @include no-svg { background-image: image-url($filename + $extension, false, false); }
}
