// basic styles, padding. detect ie variable and do fixes plus border

@mixin image-style {
  width: (100% / 2.7);
  margin-top: ms(-4);
  margin-bottom: ms(-5);
}

@mixin image-style-left {
  float: left;
  margin-right: ms()
}

@mixin image-style-right {
  float: right;
  margin-left: ms()
}

@mixin inline-image-full-width {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@mixin no-drag-image {
    user-drag: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
}

