/* _components.scss */
// @imports all SASS partials from the /components folder.
// contains styles for components constructed from objects and
// their extensions, or detailed page design

// If adding new functionlaity that is specific to the project, create a new
// SASS partial with the appropriate name and save it in the /components folder

@import 'components/brand';
@import 'components/covid19-letter';
@import 'components/decor';
@import 'components/gallery';
@import 'components/introduction';
@import 'components/notice';
@import 'components/photo-details';
@import 'components/quote';
