@mixin reset {
  margin: 0;
  padding: 0;
}

@mixin reset-font {
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}


@mixin no-bullet{
  list-style-image: none;
  list-style-type: none;
  list-style: none;
  margin-left: 0;
}

@mixin no-bullets {
  li {
    @include no-bullet;
  }
}

