//  Author: Alyssa Mahon

//  @Project Structure Overview
//  Imports are setup to help chunk different types of styles
//  for projects but also resemble a single stylesheet layed
//  out to take advantage of cascade.

// _base.scss contains 3rd party imports, custom mixins and settings
@import 'base';

// _layout.scss contains the design structure styles, 
// including responsive design implementation
@import 'layout';

// _objects.scss contains generic, underlying design patterns and their extensions
@import 'objects';

// _components.scss contains styles for components constructed from objects
// and their extensions, or detailed page design
@import 'components';

// _navigation.scss contains styles for all navigation systems.
// eg. main navigation, sub navigation, pagination, breadcrumbs, footer sitemap menus
@import 'navigation';

// @print styles
@import 'print';

// sitewide placeholder extend styles
@import 'placeholder';

// @basic greyscale design and layout for content entry purposes.
// comment out during theme development
@import 'wireframe';

// styleguide
// @import 'styleguide';
