/* _print.scss */

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

// default

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
    font-family: "Times";
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /*
  * Don't show links that are fragment identifiers,
  * or use the `javascript:` pseudo protocol
  */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  html,body{
    @include reset-font;
    font-family: Georgia, ‘Times New Roman’, serif;
    font-size: 12pt;
  }

  body{
    padding: 1em;
  }

  .container {
    margin: 0 !important;
    padding: 0 !important;
    display:  block !important;
    max-width: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include reset-font;
    @include reset;
    text-indent: 0 !important;
    margin-bottom: ms();
    margin-top: ms();
  }

  img,
  .fa,
  #footer,
  .main-nav {
    display: none !important;
  }

  img {
    display: block !important;
    margin-top: 0 !important;
  }

}

