section.covid19-letter {
	.container {
		&.header {
			@include breakpoint($smallest-screen) {
				display: block;
			}
		}
	}
	div.return {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		width: 100px;
		z-index: 5;
		&.top {
			top: 1em;
		}
		&.bottom {
			bottom: 1em;
		}
		a {
			font-size: 1.2em;
			&:before {
				@extend %icon-home;
				top: 2px;
			}
		}
		@include breakpoint($narrow-screen) {
			right: auto;
			left: auto;
		}
	}
	div.quote-holder {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		top: 3em;
		div.quote {
			position: initial;
			width: 100%;
			font-size: .9em;
		}
		@include breakpoint($smallest-screen) {
			position: absolute;
			width: 65%;
			padding: 2em;
			padding-left: 0;
		}
		@include breakpoint($small-screen-max) {
	    div.quote {
				font-size: 1.1em;
			}
	  }
	  @include breakpoint($narrow-screen) {
	  	width: 60%;
	  }
		@include breakpoint($odd-screen) {
	    width: 65%;
	  }
	  @include breakpoint($medium-screen) {
	  	width: 70%;
	  	div.quote {
				font-size: 1.25em;
			}
	  }
	}
	div.logo-container {
		position: relative;
		display: block;
		margin: 0 auto;
		width: 100%;
		margin-top: 70px;
		div.logo {
			z-index: 5;
			img {
				float: right;
				&.logo-only {
					margin-bottom: 10px;
				}
				&:not(.full-logo) {
					display: none;
				}
			}
			a {
				display: inline-block;
				img {
					&:hover {
						opacity: 1;
					}
				}
				&:hover img {
					opacity: .75;
				}
			}
		}
		@include breakpoint($smallest-screen) {
			float: right;
    	width: 25%;
    	margin-top: 48px;
			div.logo {
				position: absolute;
				right: 0;
				img {
					&:not(.full-logo) {
						display: block;
					}
					&.full-logo {
						display: none;
					}
				}
			}
		}
		@include breakpoint($small-screen) {
			width: 35%;
	  }
		@include breakpoint($odd-screen) {
			width: 25%;
	  }
	}
	div.letter {
		p {
			font-size: .8em;
		}
		ul {
			li {
				font-size: 1em;
				@include breakpoint($narrow-screen) {
			    font-size: .9em;
			  }
			}
		}
		p.identification {
			margin-bottom: 1em;
		}
		span.company-name {
			font-style: italic;
			font-weight: 400;
		}
		span.emphasis {
			font-weight: 400;
		}
		p.reminder {
			font-weight: bold;
			text-align: center;
			margin: 2em 0;
		}
		@include breakpoint($smallest-screen) {
			padding-top: 12em;
			p {
				font-size: 1em;
			}
			p.identification {
				margin-bottom: 2em;
			}
		}
		@include breakpoint($narrow-screen) {
			padding-top: 13em;
		}
		@include breakpoint($odd-screen) {
			padding-top: 12.5em;
		}
		@include breakpoint($medium-screen) {
			padding-top: 12em;
		}
	}
	div.for-more-information {
		position: relative;
		border: 1px solid $brand1;
		width: 100%;
		padding: 2em;
		margin-bottom: 3em;
		h2 {
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 2px;
			font-weight: 400;
			color: $brand1;
			margin-bottom: 1em;
		}
		div.links {
			display: inline-block;
			width: 100%;
			ul {
				display: inline-block;
				width: 100%;
				margin: 0 auto;
				padding: 0;
				@include breakpoint($standard-screen) {
					display: block;
					width: 80%;
					padding-left: 40px;
				}
				li {
					list-style-type: none;
					width: 100%;
					float: left;
					font-size: .8em;
					text-align: center;
					@include breakpoint($narrow-screen) {
						width: 50%;
						text-align: left;
					}
					@include breakpoint($odd-screen) {
						width: 100%;
						font-size: 1em;
						text-align: center;
					}
					@include breakpoint($medium-screen) {
						width: 50%;
						font-size: 1em;
						text-align: left;
					}
					// padding-left: 8em;
					&:nth-child(2n) {
						padding-left: 0;
					}
					a {
						position: relative;
						&:before {
							@extend %icon-link;
							position: absolute;
					    top: 3px;
					    right: 100%;
						}
					}
				}
			}
		}
		p {
			text-align: center;
			margin-top: 1em;
			font-size: .9em;
			@include breakpoint($narrow-screen) {
				margin-top: 2em;
				font-size: 1em;
			}
		}
	}
}