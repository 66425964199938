section.introduction {
  position: relative;
  float: right;
  width: 100%;
  background-color: white;
  @include breakpoint($medium-screen) {
    width: 50%;
    min-height: 100vh;
  }
  .main-content.container {
    min-height: auto;
    padding: 30px 5px;
    display: block;
    @include breakpoint($smallest-screen) {
      padding: 50px 15px;
    }
    @include breakpoint(1389px) {
      padding: 50px 25px;
      padding: 50px;
      min-height: calc(100vh - 50px);
    }
  }
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 2em;
    margin-top: 40px;
    color: $brand1;
    @include breakpoint($smallest-screen) {
      font-size: 2.3em;
    }
    @include breakpoint($small-screen) {
      font-size: 2.5em;
    }
    @include breakpoint($narrow-screen) {
      font-size: 3em;
    }
    @include breakpoint($odd-screen) {
      font-size: 3.3em;
    }
  }
  p.introduction {
    text-align: center;
    padding: 0 25px;
    font-size: .8em;
    @include breakpoint($narrow-screen) {
      padding: 0 50px;
      font-size: 1em;
    }
    @include breakpoint($wide-screen) {
      // padding: 0 100px;
    }
    span {
      display: inline-block;
      margin-bottom: .75em;
      &:last-child {
        margin-bottom: 0;
      }
      &.tagline {
        font-weight: bold;
        color: $brand1;
      }
    }
  }
  div.contacts {
    ul {
      text-transform: uppercase;
      padding: 0;
      text-align: center;
      margin: 10px 0 0;
      li {
        list-style-type: none;
        margin-bottom: 1.5em;
        font-size: 1em;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          &.name {
            margin-bottom: .5em;
            color: $brand2;
            font-weight: bold;
            font-size: 1.1em;
            span {
              color: $brand1;
            }
          }
          &.email,
          &.phone {
            margin-bottom: 0;
          }
          a {
            position: relative;
            display: inline-block;
            text-transform: initial;
            text-decoration: none;
            font-size: .9em;
            &.email span:before {
              @extend %icon-envelope;
              top: 7px;
              @include breakpoint($narrow-screen) {
                top: 5px;
              }
            }
            &.phone span:before {
              @extend %icon-phone;
              top: 8px;
              @include breakpoint($narrow-screen) {
                top: 6px;
              }
            }
            span {
              position: relative;
              &:before {
                position: absolute;
              }
            }
          }
        }
      }
      @include breakpoint($small-screen-max) {
        margin: 50px 0 0;
        li {
          float: left;
          width: 50%;
          font-size: 1em;
        }
      }
      @include breakpoint($narrow-screen) {
        margin: 15px 0 0;
        li {
          p.name {
            font-size: 1em;
          }
        }
      }
      @include breakpoint($odd-screen) {
        li {
          width: 100%;
        }
      }
    }
  }
  div.quote-holder {
    padding: 2em 0;
    font-size: .9em;
    @include breakpoint($wide-screen) {
      padding: 2em;
      font-size: 1em;
    }
    div.quote {
      p {
        font-size: 1.1em;
      }
    }
  }
}