@font-face {
  font-family: 'icomoon--common--font-awesome';
  src:url('../../fonts/icomoon/icomoon--common/icomoon--common--font-awesome/fonts/icomoon.eot?-c3zxka');
  src:url('../../fonts/icomoon/icomoon--common/icomoon--common--font-awesome/fonts/icomoon.eot?#iefix-c3zxka') format('embedded-opentype'),
    url('../../fonts/icomoon/icomoon--common/icomoon--common--font-awesome/fonts/icomoon.woff?-c3zxka') format('woff'),
    url('../../fonts/icomoon/icomoon--common/icomoon--common--font-awesome/fonts/icomoon.ttf?-c3zxka') format('truetype'),
    url('../../fonts/icomoon/icomoon--common/icomoon--common--font-awesome/fonts/icomoon.svg?-c3zxka#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icon-quote-right {
  @include icon('icomoon--common--font-awesome');
  content: "\e900";
}
%icon-quote-left {
  @include icon('icomoon--common--font-awesome');
  content: "\e901";
}
%icon-asterisk {
  @include icon('icomoon--common--font-awesome');
  content: "\f069";
}
%icon-plus {
  @include icon('icomoon--common--font-awesome');
  content: "\f067";
}
%icon-question {
  @include icon('icomoon--common--font-awesome');
  content: "\f128";
}
%icon-minus {
  @include icon('icomoon--common--font-awesome');
  content: "\f068";
}
%icon-glass {
  @include icon('icomoon--common--font-awesome');
  content: "\f000";
}
%icon-music {
  @include icon('icomoon--common--font-awesome');
  content: "\f001";
}
%icon-search {
  @include icon('icomoon--common--font-awesome');
  content: "\f002";
}
%icon-envelope-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f003";
}
%icon-heart {
  @include icon('icomoon--common--font-awesome');
  content: "\f004";
}
%icon-star {
  @include icon('icomoon--common--font-awesome');
  content: "\f005";
}
%icon-star-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f006";
}
%icon-user {
  @include icon('icomoon--common--font-awesome');
  content: "\f007";
}
%icon-film {
  @include icon('icomoon--common--font-awesome');
  content: "\f008";
}
%icon-th-large {
  @include icon('icomoon--common--font-awesome');
  content: "\f009";
}
%icon-th {
  @include icon('icomoon--common--font-awesome');
  content: "\f00a";
}
%icon-th-list {
  @include icon('icomoon--common--font-awesome');
  content: "\f00b";
}
%icon-check {
  @include icon('icomoon--common--font-awesome');
  content: "\f00c";
}
%icon-close {
  @include icon('icomoon--common--font-awesome');
  content: "\f00d";
}
%icon-remove {
  @include icon('icomoon--common--font-awesome');
  content: "\f00d";
}
%icon-times {
  @include icon('icomoon--common--font-awesome');
  content: "\f00d";
}
%icon-search-plus {
  @include icon('icomoon--common--font-awesome');
  content: "\f00e";
}
%icon-search-minus {
  @include icon('icomoon--common--font-awesome');
  content: "\f010";
}
%icon-cog {
  @include icon('icomoon--common--font-awesome');
  content: "\f013";
}
%icon-gear {
  @include icon('icomoon--common--font-awesome');
  content: "\f013";
}
%icon-trash-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f014";
}
%icon-home {
  @include icon('icomoon--common--font-awesome');
  content: "\f015";
}
%icon-file-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f016";
}
%icon-clock-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f017";
}
%icon-download {
  @include icon('icomoon--common--font-awesome');
  content: "\f019";
}
%icon-inbox {
  @include icon('icomoon--common--font-awesome');
  content: "\f01c";
}
%icon-lock {
  @include icon('icomoon--common--font-awesome');
  content: "\f023";
}
%icon-flag {
  @include icon('icomoon--common--font-awesome');
  content: "\f024";
}
%icon-volume-off {
  @include icon('icomoon--common--font-awesome');
  content: "\f026";
}
%icon-volume-down {
  @include icon('icomoon--common--font-awesome');
  content: "\f027";
}
%icon-volume-up {
  @include icon('icomoon--common--font-awesome');
  content: "\f028";
}
%icon-book {
  @include icon('icomoon--common--font-awesome');
  content: "\f02d";
}
%icon-bookmark {
  @include icon('icomoon--common--font-awesome');
  content: "\f02e";
}
%icon-print {
  @include icon('icomoon--common--font-awesome');
  content: "\f02f";
}
%icon-camera {
  @include icon('icomoon--common--font-awesome');
  content: "\f030";
}
%icon-video-camera {
  @include icon('icomoon--common--font-awesome');
  content: "\f03d";
}
%icon-image {
  @include icon('icomoon--common--font-awesome');
  content: "\f03e";
}
%icon-photo {
  @include icon('icomoon--common--font-awesome');
  content: "\f03e";
}
%icon-picture-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f03e";
}
%icon-pencil {
  @include icon('icomoon--common--font-awesome');
  content: "\f040";
}
%icon-map-marker {
  @include icon('icomoon--common--font-awesome');
  content: "\f041";
}
%icon-step-backward {
  @include icon('icomoon--common--font-awesome');
  content: "\f048";
}
%icon-fast-backward {
  @include icon('icomoon--common--font-awesome');
  content: "\f049";
}
%icon-backward {
  @include icon('icomoon--common--font-awesome');
  content: "\f04a";
}
%icon-play {
  @include icon('icomoon--common--font-awesome');
  content: "\f04b";
}
%icon-pause {
  @include icon('icomoon--common--font-awesome');
  content: "\f04c";
}
%icon-stop {
  @include icon('icomoon--common--font-awesome');
  content: "\f04d";
}
%icon-forward {
  @include icon('icomoon--common--font-awesome');
  content: "\f04e";
}
%icon-fast-forward {
  @include icon('icomoon--common--font-awesome');
  content: "\f050";
}
%icon-step-forward {
  @include icon('icomoon--common--font-awesome');
  content: "\f051";
}
%icon-eye {
  @include icon('icomoon--common--font-awesome');
  content: "\f06e";
}
%icon-eye-slash {
  @include icon('icomoon--common--font-awesome');
  content: "\f070";
}
%icon-exclamation-triangle {
  @include icon('icomoon--common--font-awesome');
  content: "\f071";
}
%icon-warning {
  @include icon('icomoon--common--font-awesome');
  content: "\f071";
}
%icon-plane {
  @include icon('icomoon--common--font-awesome');
  content: "\f072";
}
%icon-calendar {
  @include icon('icomoon--common--font-awesome');
  content: "\f073";
}
%icon-shopping-cart {
  @include icon('icomoon--common--font-awesome');
  content: "\f07a";
}
%icon-folder {
  @include icon('icomoon--common--font-awesome');
  content: "\f07b";
}
%icon-folder-open {
  @include icon('icomoon--common--font-awesome');
  content: "\f07c";
}
%icon-cogs {
  @include icon('icomoon--common--font-awesome');
  content: "\f085";
}
%icon-gears {
  @include icon('icomoon--common--font-awesome');
  content: "\f085";
}
%icon-star-half {
  @include icon('icomoon--common--font-awesome');
  content: "\f089";
}
%icon-heart-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f08a";
}
%icon-sign-out {
  @include icon('icomoon--common--font-awesome');
  content: "\f08b";
}
%icon-thumb-tack {
  @include icon('icomoon--common--font-awesome');
  content: "\f08d";
}
%icon-sign-in {
  @include icon('icomoon--common--font-awesome');
  content: "\f090";
}
%icon-trophy {
  @include icon('icomoon--common--font-awesome');
  content: "\f091";
}
%icon-phone {
  @include icon('icomoon--common--font-awesome');
  content: "\f095";
}
%icon-bookmark-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f097";
}
%icon-bullhorn {
  @include icon('icomoon--common--font-awesome');
  content: "\f0a1";
}
%icon-bell-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f0a2";
}
%icon-globe {
  @include icon('icomoon--common--font-awesome');
  content: "\f0ac";
}
%icon-wrench {
  @include icon('icomoon--common--font-awesome');
  content: "\f0ad";
}
%icon-briefcase {
  @include icon('icomoon--common--font-awesome');
  content: "\f0b1";
}
%icon-group {
  @include icon('icomoon--common--font-awesome');
  content: "\f0c0";
}
%icon-users {
  @include icon('icomoon--common--font-awesome');
  content: "\f0c0";
}
%icon-chain {
  @include icon('icomoon--common--font-awesome');
  content: "\f0c1";
}
%icon-link {
  @include icon('icomoon--common--font-awesome');
  content: "\f0c1";
}
%icon-cloud {
  @include icon('icomoon--common--font-awesome');
  content: "\f0c2";
}
%icon-bars {
  @include icon('icomoon--common--font-awesome');
  content: "\f0c9";
}
%icon-navicon {
  @include icon('icomoon--common--font-awesome');
  content: "\f0c9";
}
%icon-reorder {
  @include icon('icomoon--common--font-awesome');
  content: "\f0c9";
}
%icon-envelope {
  @include icon('icomoon--common--font-awesome');
  content: "\f0e0";
}
%icon-user-md {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f0";
}
%icon-stethoscope {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f1";
}
%icon-suitcase {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f2";
}
%icon-bell {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f3";
}
%icon-coffee {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f4";
}
%icon-cutlery {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f5";
}
%icon-file-text-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f6";
}
%icon-building-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f7";
}
%icon-hospital-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f8";
}
%icon-ambulance {
  @include icon('icomoon--common--font-awesome');
  content: "\f0f9";
}
%icon-medkit {
  @include icon('icomoon--common--font-awesome');
  content: "\f0fa";
}
%icon-beer {
  @include icon('icomoon--common--font-awesome');
  content: "\f0fc";
}
%icon-h-square {
  @include icon('icomoon--common--font-awesome');
  content: "\f0fd";
}
%icon-plus-square {
  @include icon('icomoon--common--font-awesome');
  content: "\f0fe";
}
%icon-location-arrow {
  @include icon('icomoon--common--font-awesome');
  content: "\f124";
}
%icon-microphone {
  @include icon('icomoon--common--font-awesome');
  content: "\f130";
}
%icon-microphone-slash {
  @include icon('icomoon--common--font-awesome');
  content: "\f131";
}
%icon-calendar-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f133";
}
%icon-file {
  @include icon('icomoon--common--font-awesome');
  content: "\f15b";
}
%icon-file-text {
  @include icon('icomoon--common--font-awesome');
  content: "\f15c";
}
%icon-thumbs-up {
  @include icon('icomoon--common--font-awesome');
  content: "\f164";
}
%icon-thumbs-down {
  @include icon('icomoon--common--font-awesome');
  content: "\f165";
}
%icon-file-image-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f1c5";
}
%icon-file-photo-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f1c5";
}
%icon-file-picture-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f1c5";
}
%icon-file-movie-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f1c8";
}
%icon-file-video-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f1c8";
}
%icon-paper-plane {
  @include icon('icomoon--common--font-awesome');
  content: "\f1d8";
}
%icon-send {
  @include icon('icomoon--common--font-awesome');
  content: "\f1d8";
}
%icon-paper-plane-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f1d9";
}
%icon-send-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f1d9";
}
%icon-newspaper-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f1ea";
}
%icon-bell-slash {
  @include icon('icomoon--common--font-awesome');
  content: "\f1f6";
}
%icon-bell-slash-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f1f7";
}
%icon-trash {
  @include icon('icomoon--common--font-awesome');
  content: "\f1f8";
}
%icon-copyright {
  @include icon('icomoon--common--font-awesome');
  content: "\f1f9";
}
%icon-at {
  @include icon('icomoon--common--font-awesome');
  content: "\f1fa";
}
%icon-eyedropper {
  @include icon('icomoon--common--font-awesome');
  content: "\f1fb";
}
%icon-paint-brush {
  @include icon('icomoon--common--font-awesome');
  content: "\f1fc";
}
%icon-birthday-cake {
  @include icon('icomoon--common--font-awesome');
  content: "\f1fd";
}
%icon-calendar-plus-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f271";
}
%icon-calendar-minus-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f272";
}
%icon-calendar-times-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f273";
}
%icon-calendar-check-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f274";
}
%icon-map-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f278";
}
%icon-map {
  @include icon('icomoon--common--font-awesome');
  content: "\f279";
}
%icon-commenting {
  @include icon('icomoon--common--font-awesome');
  content: "\f27a";
}
%icon-commenting-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f27b";
}
%icon-shopping-bag {
  @include icon('icomoon--common--font-awesome');
  content: "\f290";
}
%icon-shopping-basket {
  @include icon('icomoon--common--font-awesome');
  content: "\f291";
}
%icon-hashtag {
  @include icon('icomoon--common--font-awesome');
  content: "\f292";
}
%icon-percent {
  @include icon('icomoon--common--font-awesome');
  content: "\f295";
}
%icon-envira {
  @include icon('icomoon--common--font-awesome');
  content: "\f299";
}
%icon-envelope-open {
  @include icon('icomoon--common--font-awesome');
  content: "\f2b6";
}
%icon-envelope-open-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2b7";
}
%icon-address-book {
  @include icon('icomoon--common--font-awesome');
  content: "\f2b9";
}
%icon-address-book-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2ba";
}
%icon-address-card {
  @include icon('icomoon--common--font-awesome');
  content: "\f2bb";
}
%icon-vcard {
  @include icon('icomoon--common--font-awesome');
  content: "\f2bb";
}
%icon-address-card-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2bc";
}
%icon-vcard-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2bc";
}
%icon-user-circle {
  @include icon('icomoon--common--font-awesome');
  content: "\f2bd";
}
%icon-user-circle-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2be";
}
%icon-user-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2c0";
}
%icon-id-badge {
  @include icon('icomoon--common--font-awesome');
  content: "\f2c1";
}
%icon-drivers-license {
  @include icon('icomoon--common--font-awesome');
  content: "\f2c2";
}
%icon-id-card {
  @include icon('icomoon--common--font-awesome');
  content: "\f2c2";
}
%icon-drivers-license-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2c3";
}
%icon-id-card-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2c3";
}
%icon-window-maximize {
  @include icon('icomoon--common--font-awesome');
  content: "\f2d0";
}
%icon-window-minimize {
  @include icon('icomoon--common--font-awesome');
  content: "\f2d1";
}
%icon-window-restore {
  @include icon('icomoon--common--font-awesome');
  content: "\f2d2";
}
%icon-times-rectangle {
  @include icon('icomoon--common--font-awesome');
  content: "\f2d3";
}
%icon-window-close {
  @include icon('icomoon--common--font-awesome');
  content: "\f2d3";
}
%icon-times-rectangle-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2d4";
}
%icon-window-close-o {
  @include icon('icomoon--common--font-awesome');
  content: "\f2d4";
}