/* objects/_content-styles.scss */
// contains styles for the editor experience. eg. float images left or right,
// headline and highlight typography.
// this file gets imported by wysiwyg/wysiwyg-editor.scss.


// HEADLINE TYPE
.headline-type {
  color: $dark-grey;
  font-family: $font2;
  font-size: ms(1);
  line-height: ms(1);
  margin-bottom: ms(-3);
  @include breakpoint($narrow-screen) {
    font-size: ms(3);
  }
  b, strong{
    font-weight: $normal;
  }
}

// HIGHLIGHT TYPE
.highlight-type {
  color: $brand3;
  text-transform: uppercase;
  font-weight: $bold;
  font-size: 1em;
  font-family: $font2;
}

// HIGHLIGHT BOX
.highlight-box {
  position: relative;
  border: 1px solid $brand3;
  border-radius: 2px;
  padding: ms(-1);
  margin: ms(1);
  box-shadow: none;
  background-color: white;
  color: shade($dark-grey,45%);
  @include breakpoint($narrow-screen) {
    padding: ms(1);
  }
  font-size: ms(-1);
  line-height: ms(3);
  @include breakpoint($narrow-screen) {
    font-size: ms();
    line-height: ms(2);
  }
  p, li {
   &:last-child {
      margin-bottom: 0;
    }
  }
}

// SMALL TYPE
.small-type {
  font-size: ms(-2);
  font-weight: $bold;
  margin-bottom: ms(2);
  line-height: ms(3);
  @include breakpoint($narrow-screen) {
    line-height: ms(2);
  }
}

// ALIGN LEFT & RIGHT
img.align-right,
img.align-left {
  @include image-style;
}

// ALIGN LEFT
img.align-left {
  @include image-style-left;
}

// ALIGN RIGHT
img.align-right {
  @include image-style-right;
}

// FULL WIDTH
img.full-width {
  width: 100%;
}

.group {
  @include clearfix();
  margin: ms() 0;
}

// CTA BUTTON
a.cta-button {
  @include btn;
}

.circle {
  border-radius: 50%;
}

.hidden {
  display: none;
}

span.label {
  font-weight: $xbold;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: .7em;
}
