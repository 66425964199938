/* objects/_media-embed.scss */

// responsive media
.media-embed{
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.media-embed iframe,
.media-embed object,
.media-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
