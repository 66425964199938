//////////////////////////////
// Has Setting
//////////////////////////////
@function sgs-has($setting) {
  @if map-has-key($singularity, $setting) {
    @return true;
  }
  @else {
    @return false;
  }
}

//////////////////////////////
// Get Settings
//////////////////////////////
@function sgs-get($setting) {
  @if sgs-has($setting) {
    @return map-get($singularity, $setting);
  }
  @else {
    @return map-get($Singularity-Settings, $setting);
  }
}

//////////////////////////////
// Set Settings
//////////////////////////////
@function sgs-set($setting, $value) {
  @if (str-index($setting, '-') or str-index($setting, '_')) and str-index($setting, ' ') == null {
    @warn "Words in Singularity settings should be spaces, not dashes or underscores. Please replace dashes and underscores between words with spaces. Settings will not work as expected until changed.";
  }
  $singularity: map-merge($singularity, ($setting: $value)) !global;
  @return true;
}

@mixin sgs-change($setting, $value) {
  $sgs-change: sgs-set($setting, $value);
}

@mixin sgs-set($setting, $value) {
  @include sgs-change($setting, $value);
}

//////////////////////////////
// Remove Setting
//////////////////////////////
@function sgs-reset($settings...) {
  @if length($settings) == 1 {
    $settings: nth($settings, 1);
  }

  @each $setting in $settings {
    $singularity: map-remove($singularity, $setting) !global;
  }
  @return true;
}

@mixin sgs-reset($settings...) {
  $sgs-reset: sgs-reset($settings);
}