
/* =============================================================================
   FILE BUTTON
   ========================================================================== */

@mixin file-button {
  .field__item {
    display: inline-block;
    margin-bottom: ms(0);
  }

  a {
    @extend .btn-secondary;
    margin-right: 0;

    &:before {
      @extend %icon-download-2 !optional;
    }
  }

  span.file {
    background-image: none;
    padding-left: 0;
  }
}

