// @responsive breakpoints    ==============================================
$base-px-size: 16;

$smallest-screen: 415px;

$small-screen: 464px;
	$small-screen--val: 464;
	$small-screen--em: '#{$small-screen--val / $base-px-size}em'; // 29em
$small-screen-max: 607px;
	$small-screen-max--val: 607;
	$small-screen-max--em: '#{$small-screen-max--val / $base-px-size}em'; // 37.9375em

$narrow-screen: 608px;
	$narrow-screen--val: 608;
	$narrow-screen--em: '#{$narrow-screen--val / $base-px-size}em'; // 38em
$narrow-screen-max: 767px;
	$narrow-screen-max--val: 767;
	$narrow-screen-max--em: '#{$narrow-screen-max--val / $base-px-size}em'; // 47.9375em

$odd-screen: 768px;
	$odd-screen--val: 768;
	$odd-screen--em: '#{$odd-screen--val / $base-px-size}em'; // 48em
$odd-screen-max: 895px;
	$odd-screen-max--val: 895;
	$odd-screen-max--em: '#{$odd-screen-max--val / $base-px-size}em'; // 55.9375em

$medium-screen: 896px;
	$medium-screen--val: 896;
	$medium-screen--em: '#{$medium-screen--val / $base-px-size}em'; // 56em
$medium-screen-max: 991px;
	$medium-screen-max--val: 991;
	$medium-screen-max--em: '#{$medium-screen-max--val / $base-px-size}em'; // 61.9375em

$standard-screen: 992px;
	$standard-screen--val: 992;
	$standard-screen--em: '#{$standard-screen--val / $base-px-size}em'; // 62em
$standard-screen-max: 1187px;
	$standard-screen-max--val: 1187;
	$standard-screen-max--em: '#{$standard-screen-max--val / $base-px-size}em'; // 74.1875em

$wide-screen: 1188px;
	$wide-screen--val: 1188;
	$wide-screen--em: '#{$wide-screen--val / $base-px-size}em'; // 74.25em
$wide-screen-max: 1407px;
	$wide-screen-max--val: 1407;
	$wide-screen-max--em: '#{$wide-screen-max--val / $base-px-size}em'; // 87.9375em

$super-wide-screen: 1408px;
	$super-wide-screen--val: 1408;
	$super-wide-screen--em: '#{$super-wide-screen--val / $base-px-size}em'; // 88e
