#messages {
  display: block;
  width: 60%;
  position: relative;
  margin: 0 auto;
  div.success,
  div.fail {
    padding: 15px 20px;
    margin: 20px 0;
    border-radius: 5px;
    p {
      position: relative;
      color: white;
      font-weight: $bold;
      margin-bottom: 0;
      margin-left: 20px;
      font-size: .8em;
      &:before {
        position: absolute;
        left: -20px;
        font-weight: $bold;
        font-size: 1.1em;
      }
    }
  }
  div.success {
    $light-green: #E0F0D9;
    $green: #679F8C;
    $faded-green: #B4D39C;
    background-color: $light-green;
    border: 2px solid $faded-green;
    p {
      color: $green;
      &:before {
        @extend %icon-check !optional;
      }
    }
  }
  div.fail {
    $light-red: #FBECEC;
    $faded-red: #DDBCBD;
    $red: #DD494C;
    background-color: $light-red;
    border: 2px solid $faded-red;
    p {
      color: $red;
      &:before {
        @extend %icon-remove !optional;
      }
    }
  }
}