// @colour palettte  ==============================================
// using color names as variables is fine.

// greys
$xlight-grey: #ececec;
$light-grey: #e1e1e1;
$grey: #bebebe;
$dark-grey: #9d9d9d;
$xdark-grey: #707070;

// ui colours for shadows, borders, etc. Can be used on their own, or part of mixins or placeholders
$ui-borders-color: $dark-grey;
$ui-grey: $dark-grey;


// brand colours

// Dark blue
$brand1 : #0A4B72;
// Turqouise
$brand2 : #228189;
// Yellow
$brand3 : #C5D040;

// tints and shades
$brand2_tinted : #39B9C3;

// Off White
$brand4 : #E9F5F6;
// Dark Grey
$brand5 : #233844;
// Black
$brand6: black;
