//////////////////////////////
// CSS level 4 Viewport
// http://dev.w3.org/csswg/css-device-adapt/#the-atviewport-rule
//////////////////////////////
@mixin viewport {
  @-webkit-viewport {
    @content;
  }
  @-moz-viewport {
    @content;
  }
  @-ms-viewport {
    @content;
  }
  @viewport {
    @content;
  }
}

