@mixin icon($font:'icomoon') {
  font-family: $font;
  speak: none;
  line-height: 1;
  display: inline-block;
  position: relative;
  text-align: center;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  margin-right: .4em;
  float: left;
  top: 3px;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

@import 'icomoon/icomoon--arrows';
// @import 'icomoon/icomoon--brands';
// @import 'icomoon/icomoon--contact';
// @import 'icomoon/icomoon--payments';
// @import 'icomoon/icomoon--travel';

@import 'icomoon/icomoon--common';