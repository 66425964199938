div.quote-holder {
  padding: 2em;
}
div.quote {
	// position: absolute;
	position: relative;
	border-top: 1px solid $brand1;
	border-bottom: 1px solid $brand1;
	padding: 1em 0;
	width: 90%;
	height: 100%;
	color: $brand1;
	text-align: center;
	&.center {
		display: block;
		margin: 0 auto;
	}
	.content {
		// position: absolute;
		width: 90%;
		padding-bottom: 10px;
		&.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		p {
			width: 85%;
	    display: block;
	    margin: 0 auto;
	    margin-top: .5em;
	    font-weight: bold;
	    color: $brand1;
		}
		&:before {
			@extend %icon-quote-left;
			top: 0;
			margin-right: 5px;
		}
		&:after {
			@extend %icon-quote-right;
			float: right;
			right: 0;
	    bottom: 0;
	    margin-top: -12px;
		}
	}
}