/* layout/_footer.scss */

/* @footer section */

div.desktop-footer {
	display: none;
	.footer-cover-height {
		height: 4em;
	}
	footer {
		position: absolute;
		width: 100%;
		bottom: 0;
	}
}
@include breakpoint($medium-screen) {
	div.desktop-footer {
		display: block;
	}
  div.mobile-footer {
  	display: none;
  }
}

div.copyright {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: .8em;
  margin: .5em 0 0;
}