/* _elements.scss */
// contains unclassed HTML element styles such as h1, h2, table, etc

/* =============================================================================
    GENERAL ELEMENT STYLES
   ========================================================================== */

html {
  font-size: 100%;
  color: $dark-grey;
  overflow-y: scroll; //inuit css
  min-height: 100%; //inuit css
  font-family: $font2;
  -moz-osx-font-smoothing: grayscale; //inuit css
   -webkit-font-smoothing: antialiased; //inuit css
}

html, button, input, select, textarea {
  font-family: $font2;
  font-weight: $light;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
pre,
table,
fieldset,
form
details,
blockquote,
object,
iframe,
pre,
address,
code,
dl,
dt,
dd,
embed,
audio,
video {
  font-size: ms();
  line-height: ms(2);
  margin: 0 0 ms() 0;
}

strong, b {
  font-weight:  $bold;
}

em {
  font-style: italic;
}


// GENREAL LINK STYLES
a {
  font-weight: 400;
  color: $brand1;
  text-decoration: none;
  @extend %transition;
  &:hover {
    color: $brand2_tinted;
  }
}


// GENERAL CONTENT STYLES

// Set consistent quote types.
q {
  quotes: "\201C" "\201D" "\2018" "\2019";
}

p {
  line-height: ms(3);
  color: $brand6;
  @include breakpoint($narrow-screen) {
    font-size: ms();
    line-height: ms(2);
  }
  margin: 0 0 ms() 0;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $normal;
  font-family: $font2;
  b, strong{
    font-weight: $normal;
  }
}

h1 {
  font-size: ms(1);
  margin-bottom: ms(-5);
  line-height: ms(1);
  color: $brand3;
  @include breakpoint($narrow-screen) {
    font-size: ms(3);
  }
  a {
    color: $brand3;
  }
}

@mixin h2 {
  font-size: ms(2);
  margin-bottom: ms(-3);
  line-height: ms(1);
  color: $brand3;
  font-weight: inherit;
  @include breakpoint($narrow-screen) {
    font-size: ms(2);
  }
  a {
    color: $brand3;
  }
}

h2 {
  @include h2;
}

h3 {
  font-size: ms(1);
  margin-bottom: ms(-2);
  line-height: ms(1);
  font-weight: $bold;
  color: $brand2;
  @include breakpoint($narrow-screen) {
    font-size: ms(1);
  }
  a {
    color: $brand2;
  }
}

h4 {
  font-size: ms();
  margin-bottom: ms(-3);
  color: $dark-grey;
  @include breakpoint($narrow-screen) {
    font-size: ms();
  }
  letter-spacing: 2px;
  line-height: ms(1);
  font-weight: $bold;
}

h5 {
  font-weight: $bold;
  margin-bottom: 0;
  color: $brand1;
}

h6 {
  font-size: ms(-1);
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: $bold;
  color: $brand5;
}


// BLOCKQUOTE

blockquote {
  padding: ms(1);
  margin: 1.5em;
  background-color: lighten($brand1, 3%);
  border-left: none !important;
  width: 100%;
  display: block;
  float: left;
  p {
    font-size: ms(0);
    font-weight: $medium;
    font-family: $font2;
    color: $brand3;
    @include breakpoint($narrow-screen) {
      line-height: ms(1);
    }
    line-height: ms(2);
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// FIGCAPTIONS
figcaption {
  background: $brand2;
  padding: .6em .8em;
  font-size: .8em;
}

// FIELDSETS
fieldset {
  margin: ms(2) 0;
  border: none;
  border-bottom: solid 1px $light-grey;
  padding: ms(1) 0;

  legend {
    font-size: ms(3);
    font-weight: $bold;
    color: $brand3;
  }
}


// LABELS
label {
  // margin-bottom: ms(-2);
  font-weight: $bold;
  color: $brand5;
  display: block;
}

// INPUTS

input,
textarea {
  margin: 0;
}

textarea {
  vertical-align: top;
}

// TABLES
table {
  width: 100%;
  p, ul {
    @include reset;
  }
  tbody {
    border: 1px solid $light-grey;
  }

  th, td {
    padding: ms(-2) ms(-2);
    border: none;
    vertical-align: top;
    font-size: ms(-2);
    @include breakpoint($odd-screen) {
      font-size: ms();
      line-height: ms(1);
    }
  }

  tr.odd {
    background-color: white;
  }

  tr:nth-child(odd) {
    background-color: white;
  }

  tr.even {
    background-color: tint($brand2,1%);
  }

  tr:nth-child(even) {
    background-color: tint($brand2,1%);
  }

  th,
  thead th {
    background-color: $brand3;
    border-bottom: 1px solid $light-grey;
    color: #fff;
    .label {
      color: #fff;
    }
  }

  caption {
    caption-side: bottom;
  }
}

// LISTS

ul, ol {
  li {
    font-size: ms(-1);
    line-height: ms(3);
    @include breakpoint($odd-screen) {
      font-size: ms();
      line-height: ms(2);
    }
    margin: 0 0 ms(-3) 0;
  }
}

ul.disc {
  padding-left: ms();
  li {
    list-style: disc;
    list-style-position: inside;
  }
}

ol {
  padding-left: ms(1);
  li {
    list-style: decimal;
    list-style-position: inside;
  }
}

// IMAGES AND VIDEOS

img {
  opacity: 1;
  transition: opacity 0.3s ease-out;
  image-orientation: portrait;
}

a img {
  transform: rotate(0);
  -webkit-backface-visibility: hidden;
}

a img:hover {
  opacity: .75;
}

// from kickstart
img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  max-width: 100%;
}

section {
  padding: 0;
}




